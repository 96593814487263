<template>
    <div>
        <h2>Профиль</h2> 
        <v-row v-if="userInfo">
            <v-col lg="7" md="7" sm="12">
            <v-card class="card">
                <v-list-item three-line>
                    <v-list-item-content>
                        <div class="overline mb-4 card-name">
                            Жеке ақпарат
                        </div>
                        <v-list-item-title class="headline mb-1">
                            {{userInfo.fio.value}}
                        </v-list-item-title>
                        <v-list-item-subtitle>ЖСН: {{userInfo.iin.value}}</v-list-item-subtitle>
                        <v-list-item-subtitle>Туған күні: {{userInfo.birthday.value}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar class="card-img">
                        <v-img src="https://picsum.photos/seed/picsum/200/200?random"></v-img>
                    </v-list-item-avatar>
                </v-list-item>
            </v-card>
            </v-col> 
        <v-col lg="5" md="5" sm="12">
            <v-card class="cardContacts">
                <v-list-item three-line>
                    <v-list-item-content>
                        <div class="overline mb-4 card-name">
                            Пошталық жәшіктер және телефон нөмірлері
                        </div>
                        <v-row>
                            <v-col lg="6" class="contactsColumns">
                                <v-list-item-subtitle>Телефон нөмірі: {{userInfo.phone_number.value}}</v-list-item-subtitle>
                            </v-col>
                            <v-col lg="6">
                                <v-btn class="card-bttn">
                                Өзгерту
                            </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="6" class="contactsColumns">
                                <v-list-item-subtitle>Электронды пошта: {{userInfo.email.value}}</v-list-item-subtitle>
                            </v-col>
                            <v-col lg="6">
                                <v-btn class="card-bttn">
                                Өзгерту
                            </v-btn>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </v-list-item>
            </v-card>
        </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.getData();
        },

        data: () => ({
            userInfo: null
        }),

        methods: {
            async getData() {
                await this.$http.get('/profile').then((response) => {
                    this.userInfo = response.data.data.profile;
                });
            }    
        }
    }
</script>

<style scoped>
h2{
    font-family: Gill Sans;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    color: #203D89;
}
.title{
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #203D89;
}
.card-img{
    width: 152px;
    height: 152px;
}
.card{
    height: 200px;
}
 .card, .cardContacts {
    top: 26px;
    width: 632px;
    background: #FAFAFB;
    border-radius: 10px;
    padding: 10px 10px 20px 20px;
}
.card .card-bttn, .cardContacts .card-bttn {
    height: 24px;
    background: #203D89;
    border-radius: 10px;
    color: white;
}
.card-name{
    color: #203D89;
}
.cardContacts{
    width: 450px;
}
.cardContacts .contactsColumns{
    padding-top: 0%;
    padding-bottom: 0px;
}

</style>


